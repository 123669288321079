import React, { useEffect } from 'react'
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import { isLoggedIn } from "../services/auth"
import { navigate } from "gatsby"

const Index = props => {

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login")
        } else {
            navigate("/mainMenu")
        }
    }, [])

    return (
        <div className="granjatec-wrapper">
            <Header/>
            <HeaderMenu />
            <div className="main-container">
                <section className="section choose-opt">
                
                </section>
            </div>
            <Footer/>
        </div>
    )

} 

export default Index